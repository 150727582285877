// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---gatsby-theme-0-src-pages-404-js": () => import("./../../../../gatsby-theme-0/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-0-src-pages-404-js" */),
  "component---gatsby-theme-0-src-pages-feedback-js": () => import("./../../../../gatsby-theme-0/src/pages/feedback.js" /* webpackChunkName: "component---gatsby-theme-0-src-pages-feedback-js" */),
  "component---gatsby-theme-0-src-pages-feedback-problem-js": () => import("./../../../../gatsby-theme-0/src/pages/feedback-problem.js" /* webpackChunkName: "component---gatsby-theme-0-src-pages-feedback-problem-js" */),
  "component---gatsby-theme-0-src-pages-index-js": () => import("./../../../../gatsby-theme-0/src/pages/index.js" /* webpackChunkName: "component---gatsby-theme-0-src-pages-index-js" */),
  "component---gatsby-theme-0-src-pages-thankyou-js": () => import("./../../../../gatsby-theme-0/src/pages/thankyou.js" /* webpackChunkName: "component---gatsby-theme-0-src-pages-thankyou-js" */),
  "component---gatsby-theme-2-src-pages-giveaway-last-js": () => import("./../../../../gatsby-theme-2/src/pages/giveaway-last.js" /* webpackChunkName: "component---gatsby-theme-2-src-pages-giveaway-last-js" */),
  "component---gatsby-theme-2-src-pages-help-js": () => import("./../../../../gatsby-theme-2/src/pages/help.js" /* webpackChunkName: "component---gatsby-theme-2-src-pages-help-js" */),
  "component---gatsby-theme-2-src-templates-list-posts-js": () => import("./../../../../gatsby-theme-2/src/templates/list-posts.js" /* webpackChunkName: "component---gatsby-theme-2-src-templates-list-posts-js" */),
  "component---gatsby-theme-2-src-templates-single-post-js": () => import("./../../../../gatsby-theme-2/src/templates/single-post.js" /* webpackChunkName: "component---gatsby-theme-2-src-templates-single-post-js" */),
  "component---gatsby-theme-2-src-templates-tag-posts-js": () => import("./../../../../gatsby-theme-2/src/templates/tag-posts.js" /* webpackChunkName: "component---gatsby-theme-2-src-templates-tag-posts-js" */),
  "component---gatsby-theme-2-src-templates-tags-page-js": () => import("./../../../../gatsby-theme-2/src/templates/tags-page.js" /* webpackChunkName: "component---gatsby-theme-2-src-templates-tags-page-js" */),
  "component---src-pages-christmas-js": () => import("./../../../src/pages/christmas.js" /* webpackChunkName: "component---src-pages-christmas-js" */)
}

